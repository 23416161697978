import"core-js/modules/es.array.push.js";import{importEntities}from'soapbox/entity-store/actions';import{Entities}from'soapbox/entity-store/entities';import{accountSchema,groupSchema}from'soapbox/schemas';import{filteredArray}from'soapbox/schemas/utils';import{getSettings}from'../settings';const ACCOUNT_IMPORT='ACCOUNT_IMPORT';const ACCOUNTS_IMPORT='ACCOUNTS_IMPORT';const GROUP_IMPORT='GROUP_IMPORT';const GROUPS_IMPORT='GROUPS_IMPORT';const STATUS_IMPORT='STATUS_IMPORT';const STATUSES_IMPORT='STATUSES_IMPORT';const POLLS_IMPORT='POLLS_IMPORT';const ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP='ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP';const importAccount=data=>(dispatch,_getState)=>{dispatch({type:ACCOUNT_IMPORT,account:data});try{const account=accountSchema.parse(data);dispatch(importEntities([account],Entities.ACCOUNTS));}catch(e){//
}};const importAccounts=data=>(dispatch,_getState)=>{dispatch({type:ACCOUNTS_IMPORT,accounts:data});try{const accounts=filteredArray(accountSchema).parse(data);dispatch(importEntities(accounts,Entities.ACCOUNTS));}catch(e){//
}};const importGroup=group=>importEntities([group],Entities.GROUPS);const importGroups=groups=>importEntities(groups,Entities.GROUPS);const importStatus=(status,idempotencyKey)=>(dispatch,getState)=>{const expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUS_IMPORT,status,idempotencyKey,expandSpoilers});};const importStatuses=statuses=>(dispatch,getState)=>{const expandSpoilers=getSettings(getState()).get('expandSpoilers');return dispatch({type:STATUSES_IMPORT,statuses,expandSpoilers});};const importPolls=polls=>({type:POLLS_IMPORT,polls});const importFetchedAccount=account=>importFetchedAccounts([account]);const importFetchedAccounts=function(accounts){let args=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{should_refetch:false};const{should_refetch}=args;const normalAccounts=[];const processAccount=account=>{if(!account.id)return;if(should_refetch){account.should_refetch=true;}normalAccounts.push(account);if(account.moved){processAccount(account.moved);}};accounts.forEach(processAccount);return importAccounts(normalAccounts);};const importFetchedGroup=group=>importFetchedGroups([group]);const importFetchedGroups=groups=>{const entities=filteredArray(groupSchema).parse(groups);return importGroups(entities);};const importFetchedStatus=(status,idempotencyKey)=>dispatch=>{var _status$reblog,_status$quote,_status$pleroma,_status$pleroma$quote,_status$reblog2,_status$reblog2$quote,_status$reblog3,_status$reblog3$plero,_status$reblog3$plero2,_status$poll,_status$group;// Skip broken statuses
if(isBroken(status))return;if((_status$reblog=status.reblog)!==null&&_status$reblog!==void 0&&_status$reblog.id){dispatch(importFetchedStatus(status.reblog));}// Fedibird quotes
if((_status$quote=status.quote)!==null&&_status$quote!==void 0&&_status$quote.id){dispatch(importFetchedStatus(status.quote));}// Pleroma quotes
if((_status$pleroma=status.pleroma)!==null&&_status$pleroma!==void 0&&(_status$pleroma$quote=_status$pleroma.quote)!==null&&_status$pleroma$quote!==void 0&&_status$pleroma$quote.id){dispatch(importFetchedStatus(status.pleroma.quote));}// Fedibird quote from reblog
if((_status$reblog2=status.reblog)!==null&&_status$reblog2!==void 0&&(_status$reblog2$quote=_status$reblog2.quote)!==null&&_status$reblog2$quote!==void 0&&_status$reblog2$quote.id){dispatch(importFetchedStatus(status.reblog.quote));}// Pleroma quote from reblog
if((_status$reblog3=status.reblog)!==null&&_status$reblog3!==void 0&&(_status$reblog3$plero=_status$reblog3.pleroma)!==null&&_status$reblog3$plero!==void 0&&(_status$reblog3$plero2=_status$reblog3$plero.quote)!==null&&_status$reblog3$plero2!==void 0&&_status$reblog3$plero2.id){dispatch(importFetchedStatus(status.reblog.pleroma.quote));}if((_status$poll=status.poll)!==null&&_status$poll!==void 0&&_status$poll.id){dispatch(importFetchedPoll(status.poll));}if((_status$group=status.group)!==null&&_status$group!==void 0&&_status$group.id){dispatch(importFetchedGroup(status.group));}dispatch(importFetchedAccount(status.account));dispatch(importStatus(status,idempotencyKey));};// Sometimes Pleroma can return an empty account,
// or a repost can appear of a deleted account. Skip these statuses.
const isBroken=status=>{try{// Skip empty accounts
// https://gitlab.com/soapbox-pub/soapbox/-/issues/424
if(!status.account.id)return true;// Skip broken reposts
// https://gitlab.com/soapbox-pub/rebased/-/issues/28
if(status.reblog&&!status.reblog.account.id)return true;return false;}catch(e){return true;}};const importFetchedStatuses=statuses=>(dispatch,getState)=>{const accounts=[];const normalStatuses=[];const polls=[];function processStatus(status){var _status$reblog4,_status$quote2,_status$pleroma2,_status$pleroma2$quot,_status$poll2,_status$group2;// Skip broken statuses
if(isBroken(status))return;normalStatuses.push(status);accounts.push(status.account);if((_status$reblog4=status.reblog)!==null&&_status$reblog4!==void 0&&_status$reblog4.id){processStatus(status.reblog);}// Fedibird quotes
if((_status$quote2=status.quote)!==null&&_status$quote2!==void 0&&_status$quote2.id){processStatus(status.quote);}if((_status$pleroma2=status.pleroma)!==null&&_status$pleroma2!==void 0&&(_status$pleroma2$quot=_status$pleroma2.quote)!==null&&_status$pleroma2$quot!==void 0&&_status$pleroma2$quot.id){processStatus(status.pleroma.quote);}if((_status$poll2=status.poll)!==null&&_status$poll2!==void 0&&_status$poll2.id){polls.push(status.poll);}if((_status$group2=status.group)!==null&&_status$group2!==void 0&&_status$group2.id){dispatch(importFetchedGroup(status.group));}}statuses.forEach(processStatus);dispatch(importPolls(polls));dispatch(importFetchedAccounts(accounts));dispatch(importStatuses(normalStatuses));};const importFetchedPoll=poll=>dispatch=>{dispatch(importPolls([poll]));};const importErrorWhileFetchingAccountByUsername=username=>({type:ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP,username});export{ACCOUNT_IMPORT,ACCOUNTS_IMPORT,GROUP_IMPORT,GROUPS_IMPORT,STATUS_IMPORT,STATUSES_IMPORT,POLLS_IMPORT,ACCOUNT_FETCH_FAIL_FOR_USERNAME_LOOKUP,importAccount,importAccounts,importGroup,importGroups,importStatus,importStatuses,importPolls,importFetchedAccount,importFetchedAccounts,importFetchedGroup,importFetchedGroups,importFetchedStatus,importFetchedStatuses,importFetchedPoll,importErrorWhileFetchingAccountByUsername};