import"core-js/modules/web.dom-collections.iterator.js";import"core-js/modules/es.array.push.js";import"core-js/modules/es.regexp.exec.js";import"core-js/modules/es.string.replace.js";import split from'graphemesplit';import unicodeMapping from'./mapping';export function isCustomEmoji(emoji){return emoji.imageUrl!==undefined;}export function isNativeEmoji(emoji){return emoji.native!==undefined;}const isAlphaNumeric=c=>{const code=c.charCodeAt(0);if(!(code>47&&code<58)&&// numeric (0-9)
!(code>64&&code<91)&&// upper alpha (A-Z)
!(code>96&&code<123)){// lower alpha (a-z)
return false;}else{return true;}};const validEmojiChar=c=>{return isAlphaNumeric(c)||c==='_'||c==='-'||c==='.';};const convertCustom=(shortname,filename)=>{return"<img draggable=\"false\" class=\"emojione\" alt=\"".concat(shortname,"\" title=\"").concat(shortname,"\" src=\"").concat(filename,"\" />");};const convertUnicode=c=>{const{unified,shortcode}=unicodeMapping[c];return"<img draggable=\"false\" class=\"emojione\" alt=\"".concat(c,"\" title=\":").concat(shortcode,":\" src=\"/packs/emoji/").concat(unified,".svg\" />");};const convertEmoji=(str,customEmojis)=>{if(str.length<3)return str;if(str in customEmojis){const emoji=customEmojis[str];const filename=emoji.static_url;if((filename===null||filename===void 0?void 0:filename.length)>0){return convertCustom(str,filename);}}return str;};export const emojifyText=function(str){let customEmojis=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};let buf='';let stack='';let open=false;const clearStack=()=>{buf+=stack;open=false;stack='';};for(let c of split(str)){// convert FE0E selector to FE0F so it can be found in unimap
if(c.codePointAt(c.length-1)===65038){c=c.slice(0,-1)+String.fromCodePoint(65039);}// unqualified emojis aren't in emoji-mart's mappings so we just add FEOF
const unqualified=c+String.fromCodePoint(65039);if(c in unicodeMapping){if(open){// unicode emoji inside colon
clearStack();}buf+=convertUnicode(c);}else if(unqualified in unicodeMapping){if(open){// unicode emoji inside colon
clearStack();}buf+=convertUnicode(unqualified);}else if(c===':'){stack+=':';// we see another : we convert it and clear the stack buffer
if(open){buf+=convertEmoji(stack,customEmojis);stack='';}open=!open;}else{if(open){stack+=c;// if the stack is non-null and we see invalid chars it's a string not emoji
// so we push it to the return result and clear it
if(!validEmojiChar(c)){clearStack();}}else{buf+=c;}}}// never found a closing colon so it's just a raw string
if(open){buf+=stack;}return buf;};export const parseHTML=str=>{const tokens=[];let buf='';let stack='';let open=false;for(const c of str){if(c==='<'){if(open){tokens.push({text:true,data:stack});stack='<';}else{tokens.push({text:true,data:buf});stack='<';open=true;}}else if(c==='>'){if(open){open=false;tokens.push({text:false,data:stack+'>'});stack='';buf='';}else{buf+='>';}}else{if(open){stack+=c;}else{buf+=c;}}}if(open){tokens.push({text:true,data:buf+stack});}else if(buf!==''){tokens.push({text:true,data:buf});}return tokens;};const emojify=function(str){let customEmojis=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};return parseHTML(str).map(_ref=>{let{text,data}=_ref;if(!text)return data;if(data.length===0||data===' ')return data;return emojifyText(data,customEmojis);}).join('');};export default emojify;export const buildCustomEmojis=customEmojis=>{const emojis=[];customEmojis.forEach(emoji=>{const shortcode=emoji.get('shortcode');const url=emoji.get('static_url');const name=shortcode.replace(':','');emojis.push({id:name,name,keywords:[name],skins:[{src:url}]});});return emojis;};